<template>
  <div class="problemInfoCheck">
    <h1>点位问题审核</h1>
    <el-form :inline="true">
      <el-form-item label="问题时间">
        <el-date-picker
          v-model="timeValues"
          type="daterange"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
        />
      </el-form-item>
      <el-form-item label="点位类型">
        <el-select
          v-model="searchParam.pointPositionType"
          filterable
          style="width: 120px;"
          clearable
        >
          <el-option
            v-for="item in show.pointTypes"
            :key="item.id"
            :label="item.value"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="是否反复">
        <el-select v-model="searchParam.hasRepeat" filterable style="width: 120px;" clearable>
          <el-option label="是" value="true" />
          <el-option label="否" value="false" />
        </el-select>
      </el-form-item>
      <el-form-item label="问题程度">
        <el-select v-model="searchParam.problemLevel" filterable style="width: 120px;" clearable>
          <el-option
            v-for="item in show.problemLevels"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="searchParam.problemStatus" filterable style="width: 120px;" clearable>
          <el-option
            v-for="item in show.problemStatusList"
            :key="item.index"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          style="width: 130px;"
          v-model="searchParam.labelFirst"
          clearable
          filterable
          placeholder="标签1"
        >
          <el-option
            v-for="item in show.labelOptions1"
            :key="item.value"
            :label="item.value"
            :value="item.value"
          />
        </el-select>
        <el-select
          style="width: 130px;"
          v-model="searchParam.labelSecond"
          clearable
          filterable
          placeholder="标签2"
        >
          <el-option
            v-for="item in show.labelOptions2"
            :key="item.value"
            :label="item.value"
            :value="item.value"
          />
        </el-select>
        <el-select
          style="width: 130px;"
          v-model="searchParam.labelThird"
          clearable
          filterable
          placeholder="标签3"
        >
          <el-option
            v-for="item in show.labelOptions3"
            :key="item.value"
            :label="item.value"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-form-item label="责任单位">
          <el-input v-model="searchParam.responsibleUnitNameLike" style="width: 180px;" />
        </el-form-item>
        <el-form-item label="所属辖区" label-width="100px">
          <el-select v-model="searchParam.areaCode" filterable remote reserve-keyword placeholder="输入名称搜索并选择" :remote-method="loadAreaCodeOptions" style="width: 180px">
              <el-option v-for="item in show.areaCodeOptions" :key="item.areaCode" :label="item.fullName + '(' + item.areaCode + ')'" :value="item.areaCode" />
          </el-select>
        </el-form-item>
        <el-button-group>
          <el-button type="primary" @click="commitFindList()">搜索</el-button>
          <el-button @click="resetSearchParam()">重置</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table
      :data="data.list"
      @selection-change="selectionChange()"
      ref="tabRef"
      border
      style="height: calc(100vh - 350px);"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column prop="pointPositionName" label="点位" show-overflow-tooltip />
      <el-table-column prop="standardName" label="标准" show-overflow-tooltip />
      <el-table-column prop="reportTime" label="问题时间" show-overflow-tooltip />
      <el-table-column prop="labels" label="标签" show-overflow-tooltip>
        <template #default="scope">
          <span v-if="scope.row.labelFirst">{{ scope.row.labelFirst + "," }}</span>
          <span v-if="scope.row.labelSecond">{{ scope.row.labelSecond + "," }}</span>
          <span v-if="scope.row.labelThird">{{ scope.row.labelThird }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="hasRepeat" label="是否反复" width="100px" show-overflow-tooltip>
        <template #default="scope">
          <span v-if="scope.row.hasRepeat == true">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column>
      <el-table-column prop="problemLevel" label="问题程度" width="100px" show-overflow-tooltip />
      <el-table-column prop="score" label="分值" width="100px" show-overflow-tooltip />
      <el-table-column prop="rectifyTime" label="整改时间" show-overflow-tooltip />
      <el-table-column prop="problemStatus" label="整改状态" show-overflow-tooltip>
        <template #default="scope">
          <template v-for="item in show.problemStatusList">
            <span :key="item.index" v-if="item.value == scope.row.problemStatus">{{ item.label }}</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="pointPositionType" label="点位类型" show-overflow-tooltip />
      <el-table-column prop="responsibleUnitName" label="责任单位" show-overflow-tooltip />
      <el-table-column prop="areaName" label="所属辖区" show-overflow-tooltip />
      <el-table-column fixed="right" label="操作">
        <template #default="scope">
          <el-button v-if="scope.row.problemStatus == 'waitCheck'" type="primary" text @click="check(scope.row)">审核</el-button>
          <el-button v-else type="primary" text @click="check(scope.row)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 10px;" />
    <el-pagination
      v-model:current-page="searchParam.pageNum"
      v-model:page-size="searchParam.pageSize"
      :page-sizes="[50, 100, 200]"
      background
      layout="total, sizes, prev, pager, next, jumper"
      :total="data.total"
      @change="commitFindList()"
    />

    <el-dialog v-model="show.editShow"  :title="data.editData.problemStatus=='waitCheck' ? '审核' : '详情'"  width="1000">
      <ProblemDetail :problem="data.editData"/>
      <template #footer v-if="data.editData.problemStatus == 'waitCheck'">
        <div class="dialog-footer">
          <el-button type="danger" @click="checkUnPass(data.editData.id)">驳回</el-button>
          <el-button type="primary" @click="checkPass(data.editData.id)">通过</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";
import { ElMessage,ElMessageBox  } from "element-plus";
import api from "../../apis/api";
import cache from "../../utils/cache";
import ProblemDetail from "../../components/ProblemDetail.vue";

const tabRef = ref();
const timeValues = ref('')
const data = reactive({
  total: 0,
  list: [],
  accumulate: {},
  editData: {},
});

const show = reactive({
  selected: [],
  importShow: false,
  problemOptions: [],
  labelOptions1: [],
  labelOptions2: [],
  labelOptions3: [],
  pointTypes: [],
  problemTypes: [],
  problemLevels: [
    {
      label: "1处",
      value: 1
    },
    {
      label: "2处",
      value: 2
    },
    {
      label: "3处",
      value: 3
    },
    {
      label: "4处",
      value: 4
    },
    {
      label: "5处",
      value: 5
    },
    {
      label: "6处",
      value: 6
    },
    {
      label: "7处",
      value: 7
    },
    {
      label: "8处",
      value: 8
    },
    {
      label: "9处",
      value: 9
    },
    {
      label: "10处",
      value: 10
    }
  ],
  problemStatusList: [
    {
      label: "考核通过",
      value: "checkPass"
    },
    {
      label: "待审核",
      value: "waitCheck"
    },
    {
      label: "审核不通过",
      value: "checkUnPass"
    },
    {
      label: "待整改",
      value: "waitRectify"
    },
    {
      label: "整改待初审",
      value: "waitFirstReview"
    },
    {
      label: "初审被驳回",
      value: "firstReject",
    },
    {
      label: "待终审",
      value: "waitEndReview"
    },
    {
      label: "整改通过",
      value: "reviewPass"
    },
    {
      label: "终审被驳回",
      value: "endReject",
    },
  ],
  areaCodeOptions:[]
});

const searchParam = reactive({});
const loadAreaCodeOptions = (keyword) => {
    const params = {
        keyWord: keyword
    }
    api.get('/backend/ad/page', { params }).then(res => {
        show.areaCodeOptions = res.list
    })
}
const resetSearchParam = () => {
  searchParam.pageNum = 1;
  searchParam.pageSize = 50;
  searchParam.labelFirst = null;
  searchParam.labelSecond = null;
  searchParam.labelThird = null;
  searchParam.startReportTime = null;
  searchParam.endReportTime = null;
  searchParam.pointPositionType = null;
  searchParam.hasRepeat = null;
  searchParam.problemLevel = null;
  searchParam.problemStatus = "waitCheck";
  searchParam.responsibleUnitNameLike = null;
  searchParam.areaCode = null;
};
resetSearchParam();

const beforeSearchParam = cache.getObject(cache.keys.pageParam + "problem");
if (beforeSearchParam) {
  Object.assign(searchParam, beforeSearchParam);
  cache.setObject(cache.keys.pageParam + "problem", null);
}

const getPageParam = () => {
  const params = {};
  params.pageNum = searchParam.pageNum;
  params.pageSize = searchParam.pageSize;
  params.labelFirst = searchParam.labelFirst;
  params.labelSecond = searchParam.labelSecond;
  params.labelThird = searchParam.labelThird;
  params.startReportTime = searchParam.startReportTime;
  params.endReportTime = searchParam.endReportTime;
  params.pointPositionType = searchParam.pointPositionType;
  params.hasRepeat = searchParam.hasRepeat;
  params.problemLevel = searchParam.problemLevel;
  params.problemStatus = searchParam.problemStatus;
  params.responsibleUnitNameLike = searchParam.responsibleUnitNameLike;
  params.areaCode = searchParam.areaCode;
  return params;
};

const commitFindList = () => {
  getDatePickerValue()
  api.get("/backend/problem/page", { params: getPageParam() }).then(res => {
    data.list = res.list;
    data.total = res.total;
  });
};
const getDatePickerValue = () => {
    searchParam.startReportTime = timeValues.value[0]
    searchParam.endReportTime = timeValues.value[1]
};
commitFindList();

const selectionChange = () => {
  show.selected = tabRef.value.getSelectionRows();
};

const check = row => {
  const problem = row ? row : tabRef.value.getSelectionRows()[0];
  data.editData = JSON.parse(JSON.stringify(problem));
  if (data.editData.problemPics) {
    data.editData.problemPicsArray = JSON.parse(data.editData.problemPics);
  } else {
    data.editData.problemPicsArray = [];
  }
  show.editShow = true;
};

const checkPass = id => {
  id = JSON.parse(JSON.stringify(id));
  api.post("/backend/problem/checkPass", { id: id }).then(() => {
    ElMessage.success("审核成功");
    show.editShow = false;
    commitFindList();
  });
};

const checkUnPass = id => {
  ElMessageBox.prompt('请输入驳回理由', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
  }).then(({ value }) => {
    api.post("/backend/problem/checkUnPass", { id: id, checkReason: value }).then(() => {
      ElMessage.success("审核成功");
      show.editShow = false;
      commitFindList();
    });
  }).catch(() => {
  });
};

api.get("/backend/dict/getByCode", { params: { code: "label1" } }).then(res => {
  show.labelOptions1 = res.dictList;
});
api.get("/backend/dict/getByCode", { params: { code: "label2" } }).then(res => {
  show.labelOptions2 = res.dictList;
});
api.get("/backend/dict/getByCode", { params: { code: "label3" } }).then(res => {
  show.labelOptions3 = res.dictList;
});
api
  .get("/backend/dict/getByCode", { params: { code: "pointType" } })
  .then(res => {
    show.pointTypes = res.dictList;
  });
api
  .get("/backend/dict/getByCode", { params: { code: "problemType" } })
  .then(res => {
    show.problemTypes = res.dictList;
  });
// api
//   .get("/backend/dict/getByCode", { params: { code: "problemLevel" } })
//   .then(res => {
//     show.problemLevels = res.dictList;
//   });
</script>

<style lang="less"></style>